import React from "react"
import Helmet from "react-helmet"
import Layout from "../../../components/layout"
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import PageHeader from "../../../components/page-header"
import ContactForm from "../../../components/contact-us-form"

export default () => (
	<Layout style={{ color: `teal` }}>
		<Helmet title="Chapter 13 Bankruptcy Lawyers | RWK Attorneys" defer={false} />

		<PageHeader>
			<Row>
				<Col>
					<nav aria-label="breadcrumb">
						<ol className="breadcrumb pb-0">
							<li class="breadcrumb-item"><Link to="/">Home</Link></li>
							<li class="breadcrumb-item"><Link to="/practice-areas">Practice Areas</Link></li>
							<li class="breadcrumb-item"><Link to="/practice-areas/bankruptcy">Bankruptcy</Link></li>
							<li class="breadcrumb-item active" aria-current="page">Chapter 13</li>
						</ol>
					</nav>
					<h1>Chapter 13<br /><small>Bankruptcy Law</small></h1>
				</Col>
			</Row>
		</PageHeader>

		<Container>
			<Row>
				<Col md="3">
					<ContactForm />
				</Col>
				<Col>
					<h1>Chapter 13 Bankruptcies</h1>

					<p>Chapter 13 bankruptcy allows you to pay back all or a portion of your debt over a set period of time, up to five (5) years. Chapter 13 bankruptcy is often called "Debtor’s Court." This type of bankruptcy is designed to give a person a chance to repay or catch up debt that has become overwhelming. The benefit to this type of bankruptcy is that the debtor will be under the protection of federal bankruptcy laws which prevent certain actions by creditors, such as filing lawsuits, collection calls, dunning letters, repossessing a car, or foreclosing on a home.</p>

					<p>One of the most important benefits in filing a Chapter 13 bankruptcy is that it consolidates the debt and allows you to pay the debt back at a fixed monthly payment each month. Another important benefit is that you are likely able to retain valuable property, such as a home, land, or a car, which might otherwise be lost if a chapter 7 bankruptcy case is filed. Other benefits include the possible reduction of interest on car loans and other collateral, while paying little or no interest to unsecured creditors, such as credit cards. And remember, the bankruptcy stops creditors from attempting to collect the debt owed by means of phone calls, letters, repossession, foreclosure or other means.</p>

					<p>A Chapter 13 bankruptcy will help you if:</p>
					<ul>
						<li>You have fallen behind on your mortgage payments or car payments and are at risk of losing your home or car;</li>
						<li>You have substantial value or equity in property or assets which may be lost in a Chapter 7 bankruptcy;</li>
						<li>You are behind on debt payments but can pay the debt back over time;</li>
						<li>You have filed a Chapter 7 bankruptcy within the past eight (8 ) years and need the protection of the bankruptcy laws again.</li>
					</ul>

					<h2 className="mt-5">FAQ</h2>
					<p className="lead">How are my Chapter 13 bankruptcy payments determined?</p>
					<p>In a Chapter 13 bankruptcy a debtor will file a “plan” which will outline to the court, the trustee and his creditors, how he proposes to pay the debt back.&nbsp; The “plan” will provide for monthly payments to be made by the debtor to the trustee.&nbsp; Payments will be determined by the current income of the debtor, the amount of debt owed by the debtor and how much time the debtor proposes to pay back the debt.&nbsp; A debtor may not propose a plan that lasts longer than five years.</p>
					<p className="lead">What debts are included in a Chapter 13 Bankruptcy?</p>
					<p>All debts currently owed by the debtor must be included in the Chapter 13 plan.&nbsp; Debt falls into one of three categories: secured debt such as mortgage payments, car payments and furniture payments; unsecured priority debts such as taxes and child support; and unsecured nonpriority debts such as credit cards, personal loans and medical bills.&nbsp; Long term debts, such as a mortgage, will be able to be paid directly by a debtor to the mortgagee outside of the bankruptcy case, but any arrears (i.e., past due amount) must be listed and paid in the case.&nbsp; Likewise, if the debtor is paying for a vehicle, then the debt for the vehicle must be included in the bankruptcy case, regardless of whether he is current or behind on the payment.</p>
					<p className="lead">Will I have to go to court?</p>
					<p>Yes.&nbsp; There will be at least two scheduled hearings upon the filing of a Chapter 13 plan.&nbsp; Attendance is mandatory to the first hearing which is called the “Meeting of Creditors.”&nbsp; This hearing is usually scheduled about 30 days from the date you file your bankruptcy case.&nbsp; The “Meeting of Creditors” is designed to allow your creditors to ask you any questions about your assets and liabilities.&nbsp; For the most part, very few creditors will attend this hearing.&nbsp; Also at this hearing, the trustee will determine whether the monthly payment is sufficient to pay the debts in full by the end of the plan term.</p>
					<p>The second hearing is known as the “Confirmation Hearing.”&nbsp; This hearing is normally scheduled approximately 30 days from the “Meeting of the Creditors.”&nbsp; This hearing is required, and it is designed to allow the Judge an opportunity to review the case and make sure that the case satisfies the requirements of the law.&nbsp; It also is designed to make sure that a debtor has made all of his current monthly mortgage or rent payments as well as all bankruptcy payments.</p>
					<p className="lead">How long does bankruptcy last?</p>
					<p>A Chapter 13 plan shall not last longer than 60 months or five (5) years, but a plan may be as little as three (3) years.&nbsp; The length of the plan will be determined by how much a debtor can afford to pay each month and the amount and type of debt owed.</p>
					<p className="lead">Can I file by myself or does my spouse have to file with me?</p>
					<p>While only one spouse may be interested in filing a bankruptcy case, it may be advantageous for both spouses to file together.&nbsp; If there is a lot of joint debt, it may be better for both spouses to file a joint petition.</p>

					<p className="lead">Will a bankruptcy affect my credit report?</p>
					<p>A chapter 13 bankruptcy case will be reported on the credit report.&nbsp; In most cases, your credit score will be reduced once a bankruptcy case is filed.&nbsp; After you receive your discharge, however, the balances on the debts which you bankrupted on must show a zero balance on your credit report.&nbsp; Discharged debts incorrectly reported as having a balance may negatively affect your credit report.&nbsp; You should check your credit report after your bankruptcy case is over with to verify that the accounts all show a zero balance.&nbsp; If something is incorrect, you can contact our office for more information.</p>
					<p className="lead">What if there is a co-signer on a debt of mine?</p>
					<p>If there is a co-signer on a credit card, medical bill, personal loan, or other such bill, then the debt still has to be listed in the plan.&nbsp; The co-signer will be protected by the Automatic Stay which means that the creditor can not attempt to collect the debt by any means from the co-signor as well.&nbsp; However, if that bill is not paid during the case, the co-signer may be responsible for paying that bill once the bankruptcy case has been discharged.</p>
					<p className="lead">Will bankruptcy stop a garnishment, lawsuit, foreclosure, or repossession?</p>
					<p>Generally speaking, yes.&nbsp; When you file your bankruptcy case, an automatic stay goes into effect.&nbsp; With some exceptions, the automatic stay prevents a creditor from continuing its collection efforts against you, such as filing a lawsuit, calling you, writing you, repossessing a vehicle, or foreclosing on a home.&nbsp; If a lawsuit has already been filed or if a garnishment is already pending, the automatic stay also stops the lawsuit and garnishment from continuing.&nbsp; However, if a debtor has had one or more cases pending within a year, he must ask permission from the court to extend the protections (or impose them) of the automatic stay.</p>
					<p className="lead">What are the costs for filing bankruptcy?</p>
					<p>The filing fee is $274.00.&nbsp; The credit counseling fee is $50.00.&nbsp; The fee for obtaining your credit report is $20.00 per person.&nbsp; The attorney fee varies from case to case but is determined by the amount of time we will work on the case until you receive your discharge.&nbsp; The credit counseling fee and the credit report fee will need to be paid before we can file your plan.&nbsp; The filing fee can usually be rolled into the plan meaning that you will not need to pay the filing fee up front.&nbsp; The trustee will collect $274.00 from your first two or three trustee payments.&nbsp; Normally, we will be able to roll most of our attorney fee into the plan and charge a portion of our fee up front.&nbsp; That means our fee will be paid by distributions made from the trustee after he receives your monthly payments.</p>

				</Col>
			</Row>

		</Container>
	</Layout>
)